@import url('./font/stylesheet.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Axiforma';
}

li {
  list-style: none;
}


.links {
  color: #e3702c;
  text-decoration: none;
  font-size: 25px;
  font-weight: 700;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(./images/welcome.jpg);
  background-size: cover;
  height: 650px;
}
.welcome-note {
  font-size: 50px;
  font-weight: bold;
  padding-top: 10em;
  color: #e3702c;
}

.welcome-note-span {
  padding-bottom: 20em;
  font-style: normal;
  font-size: 24px;
  color: rgb(2,0,36);
}

.short-note {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-top: 50px;
}

.short-note span {
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  color: #e3702c;
}

.content {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-left: 25px;
  padding-bottom: 20px;
}

.content p {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  background-color: aliceblue;
  color: black;
  padding: 10px 10px 10px 10px;
  border-radius: 15px;
  z-index: -1;
}

.img1 {
  padding: 5px;
  rotate: -4deg;
  border-radius: 15px;
  z-index: -1;
}

.img2 {
  padding: 10px;
  rotate: 4deg;
  border-radius: 20px;
  z-index: -1;
}

.img3 {
  padding: 15px;
  rotate: -4deg;
  border-radius: 30px;
  z-index: -1;
}
.stress-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}

.stress-img {
  rotate: 5deg;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  z-index: -1;
}

.stress {
  display: flex;
  padding: 50px;
  flex-direction: column;
  background-color: aliceblue;
  border-radius: 7px;
}

.stress-title {
  text-align: center;
  font-size: 30px;
  font-style: normal;
  border-top: 2px solid #e3702c;
  color: #e3702c;
}

.stress-para {
  text-align: justify;
  color: black;
}


.explore-container {
  background-image: url(./images/map2.jpg);
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 500px;
  padding-bottom: 50px;
}

.explore-container h2 {
  color: #e3702c;
  font-family: 'Axiforma';
  font-size: 26px;
  font-weight: 800;
  padding-top: 20px;
}

.explore-container p {
  color: black;
  font-size: 19px;
  font-weight: 600;
  font-family: 'Axiforma';
  font-style: normal;
  padding-bottom: 30px;
}

.btn-explore {
  color: white;
  font-weight: 600;
  font-style: normal;
  font-family: 'Axiforma';
  font-size: 18px;
  background-color: #e3702c;
  border: none;
  border-radius: 60px;
  padding: 10px;
}

.btn-explore:hover {
  background-color: rgb(12, 41, 4);
}


.btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.message-btn {
  color: #e3702c;
}

.btn-explore1 {
  color: white;
  font-weight: 600;
  font-style: normal;
  font-family: 'Axiforma';
  font-size: 18px;
  background-color: #e3702c;
  border: none;
  border-radius: 60px;
  padding: 10px;
}

.btn-explore1:hover {
  background-color: rgb(12, 41, 4);
}

.form-title {
  background-image: url(./images/water.jpg);
  background-size: cover;
  width: 100%;
  height: 600px;
}

.form-title h2 {
  padding: 15%;
  color: rgb(2,0,36);
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.talk-us {
  color: #e3702c
}

.contact1-control {
  padding-left: 10em;
  padding-top: 4%;
  padding-bottom: 5%;
  z-index: -1;
}

.contact1-control h2 {
  text-transform: uppercase;
  font-size: 30px;
}

.contact1-control h2, h3, p {
  color: #e3702c;
  padding: 0.1rem;
  opacity: 0.8;
}

.service-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10%;
}

.service-container h2 {
  font-family: 'Axiforma';
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.service-container span {
  font-size: 14px;
  font-family: 'Axiforma';
  font-style: italic;
  font-weight: 500;
}

.service-container p {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  z-index: -1;
}

.service-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 100px;
}

.flex-item {
  background: tomato;
  width: 500px;
  height: 250px;
  margin-top: 10px;
  color: white;
  text-align: left;
  z-index: -1;
  border-radius: 10px;
}

.flex-item h3 {
  text-align: center;
  text-transform: capitalize;
}

.flex-item h5 {
  text-align: center;
  font-size: 40px;
}


.vision-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2% 10%;
  z-index: -1;
}

.vision-title {
  font-size: 30px;
  font-weight: 700;
  color: #e3702c;
}

.vision-design {
  font-weight: 900;
  color: #e3702c;
  padding-bottom: 10px;
}

.vision-para {
  font-size: 15px;
  text-align: justify;
  padding-right: 30px;
  color: black;
}

.about-title {
  background-image: url(./images/birds.jpg);
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 600px;
}

.about-title h2 {
  padding-top: 20%;
  color: white;
  font-size: 58px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
}

.core-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 10%;
}

.core-values {
  padding-right: 50px;
}

.core-values h2{
  text-transform: uppercase;
  font-size: 25px;
  text-decoration: underline;
}

.core-values h4 {
  font-size: 20px;
  color: #e3702c;
}

.core-values p {
  color: black;
  font-size: 14px;
  text-align: justify;
}

.core-mission h2{
  text-transform: uppercase;
  font-size: 20px;
  text-decoration: underline;
  color: #e3702c;
}

.core-mission p {
  color: black;
  font-size: 14px;
  z-index: -1;
}

.footer1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  width: 100%;
  background-color: #1f5156;
}

.footer1-container p {
  color: white;
}

/* Extra small device (phones, 600px and down) */
@media screen and (max-width: 955px) {
  .welcome-note {
    font-size: 30px;
    text-align: center;
  }
  .welcome-note-span {
    font-size: 20px;
    text-align: center;
  }
  .short-note span {
    font-size: 18px;
  }
  .content {
    padding: 0%;
    flex-direction: column;
  }
  .content p {
    font-size: 12px;
    text-align: justify;
  }
  .content img {
    width: 250px;
    height: 200px;
  }
  .explore-container {
    padding: 25px;
    background-size: contain;
  }
  .explore-container h2 {
    font-size: 13px;
    padding: 0%;
  }
  .explore-container p {
    font-size: 10px;
  }
  .btn-explore {
    font-size: 10px;
    margin: 0%;
  }
  .stress-content {
    flex-direction: column;
  }
  .stress-img {
    width: 150px;
    height: 250px;
    margin-bottom: 8%;
  }
  .stress {
    padding: 5%;
  }
  .stress-title {
    font-size: 16px;
  }
  .stress-para {
    text-align: left;
    font-size: 13px;
  }
  .footer1-container {
    padding: 0%;
  }
  .about-title {
    background-size: contain;
    height: 200px;
  }
  .about-title h2 {
    font-size: 29px;
  }
  .vision-container {
    flex-direction: column;
    padding: 0%;
  }
  .vision-title {
    padding-left: 5%;
    padding-top: 5%;
  }
  .vision-design {
    padding-left: 5%;
  }
  .vision-para {
    padding-left: 5%;
    padding-right: 5%;
    background-color: aliceblue;
  }
  .vision-img {
    width: 300px;
    height: 300px;
  }
  .core-container {
    padding: 5%;
  }
  .core-values {
    padding-right: 5%;
  }
  .core-values h2 {
    font-size: 15px;
  }
  .core-values h4 {
    font-size: 15px;
  }
  .core-values p {
    font-size: 8px;
  }
  .core-mission h2 {
    font-size: 15px;
  }
  .core-mission p {
    font-size: 8px;
    text-align: justify;
  }
  .service-items {
    padding: 5%;
  }
  .form-title {
    height: 200px;
    background-size: contain;
  }
  .form-title h2 {
    font-size: 30px;
  }
  .form-container {
    flex-direction: column;
    padding: 5%;
    display: flex;
    align-items: flex-start;
  }
  .contact1-control {
    padding-top: 5%;
    padding-left: 0%;
  }
  .contact1-control h2 {
    font-size: 20px;
  }
  .contact1-control h3 {
    font-size: 15px;
  }
  .contact1-control p {
    font-size: 12px;
  }
  .footer1-container {
    padding-left: 3%;
  }
}


/* Small device (portrait tablets and large phones, 600px and up) */
@media screen and (min-width: 600px){
  
}

/* Media devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px){
   
}
/* Large devices (laptops/desktop, 992px and up) */
@media screen and (min-width: 992px){
   
}
/* Extra large devices (large laptops and desktop, 1200px and up */
@media screen and (min-width: 1200px){
  
}